<template>
  <div>
    <div class="row align-items-end">
      <div class="col-3">
        <label for=""> Employé <span class="text-danger">*</span> </label>
        <select
          v-model="search.employee_reference"
          class="form-select"
          required
        >
          <option selected value="--">--</option>
          <option
            v-for="(employee, index) in employees"
            :key="index++"
            :value="employee.reference"
            
          >
            {{ employee.fullName }} {{ employee.society }}
          </option>
        </select>
      </div>

      <div class="col">
        <label for="">Du <span class="text-danger">*</span> </label>
        <input v-model="search.startDate" type="date" class="form-control" />
      </div>
      <div class="col">
        <label for=""> Au <span class="text-danger">*</span> </label>
        <input v-model="search.endDate" type="date" class="form-control" />
      </div>

      <div class="col-2">
        <label for=""> État de paiement </label>
        <select v-model="search.paymentStatus" class="form-select">
          <option value="--">--</option>
          <option value="Payé">Payé</option>
          <option value="Impayé">Impayé</option>
        </select>
      </div>
      <div class="col-auto">
        <label for=""> </label>
        <button @click="getReport(search)" class="btn btn0 btn-secondary">
          <i class="bi bi-funnel"></i> Rechercher
        </button>
      </div>
      <div class="col-auto">
        <label for=""> </label>
        <button @click="refresh()" class="btn btn0 btn-secondary">
          <i class="bi bi-funnel"></i> Actualiser
        </button>
      </div>
    </div>
    <br />
    <vue-data-table 
      :headers="headers" 
      :data="reports" 
      :items-per-page="10" 
      :buttons="buttons"
      :summable-columns="summableColumns"
    />
    <br />
  

    <div v-if="reports != ''" class="row justify-content-end">
      <div class="col-auto">
        <label for=""> </label>
        <button
          @click="sendByEmail(search)"
          class="btn btn0 btn-secondary"
        >
          <i class="bi bi-cloud-arrow-down"></i>
          Envoyer par email
        </button>
      </div>

      <div class="col-auto">
        <label for=""> </label>
        <button
          @click="printGeneralReport(search)"
          class="btn btn0 btn-secondary"
        >
          <i class="bi bi-cloud-arrow-down"></i>
          Télécharger État Général
        </button>
      </div>

      <div class="col-auto">
        <label for=""> </label>
        <button @click="print(search)" class="btn btn0 btn-secondary">
          <i class="bi bi-cloud-arrow-down"></i>
          Télécharger État Détaillé
        </button>
      </div>

      <div class="col-auto">
        <label for=""> </label>
        <button @click="archive(search)" class="btn btn-warning">
          <i class="bi bi-archive"></i>
          Archivé
        </button>
      </div>
    </div>

   
    <br />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "axios";
import LineChart from "../../components/chart/LineChart.vue";

export default {
  components: {
    LineChart,
  },

  data() {
    return {
      search: {
        startDate: new Date(new Date().setFullYear(new Date().getFullYear()))
          .toLocaleString("en-CA")
          .split(",")[0],

        endDate: new Date(new Date().setFullYear(new Date().getFullYear()))
          .toLocaleString("en-CA")
          .split(",")[0],
      },
      employee_reference: "",
      headers: [
        { field: 'customerReference', label: 'Référence', class: '' },
        { field: 'customerFullName', label: 'Client', class: '' },
        { field: 'employeeFullName', label: 'Responsable', class: '' },
        { field: 'totalPriceTTC', label: 'Total TTC', class: 'text-primary' },
        { field: 'totalAmountPaid', label: 'Total Payé', class: 'text-success' },
        { field: 'totalAmountUnpaid', label: 'Total Impayé', class: 'text-danger' },
        { field: 'totalDiscountAmount', label: 'Total Remise', class: '' },
        { field: 'totalQuantity', label: 'Quantité Total', class: '' },
      ],

      buttons: [
       
      ],

      summableColumns: [
        // { field: 'totalPriceTTC', class: 'text-primary' },
        // { field: 'totalAmountPaid', class: 'text-success' },
        // { field: 'totalAmountUnpaid', class: 'text-danger' },
        // { field: 'totalDiscountAmount', class: '' },
        // { field: 'totalQuantity', class: '' },
      ]
    };
  },

  beforeMount() {
    this.$store.dispatch("employee/getAll");
    this.$store.dispatch("sale/reportCustomer", this.search);
    // this.$store.dispatch("sale/show", customer_reference);
  },
  computed: {
    ...mapGetters("employee", {
      employees: "getAll",
    }),
    ...mapGetters("report", {
      reports: "getEmployeeReport",
     
    }),
  },
  methods: {
    async refresh() {
      await this.$store.dispatch("employee/getAll");
      this.search = {};
    },
    async getReport(data) {
      await this.$store.dispatch("report/fetchReportEmployee", data);
    },
    async print(data) {
      await this.$store.dispatch("report/printReportCustomer", data);
    },
    async printGeneralReport(data) {
      await this.$store.dispatch("report/printGeneralReportEmployee", data);
    },

    async sendByEmail(data) {
      await this.$store.dispatch("report/sendByEmailReportEmployee", data);
    },
    async archive(data) {
      await this.$confirm({
        message:
          "Souhaitez-vous mettre en archives l'ensemble des factures et paiements liés à ce client ?",
        button: {
          no: "Non",
          yes: "Oui", 
        },

        callback: (confirm) => {
          if (confirm) {
            this.$store.dispatch("archive/storeCustomerReport", data);
            this.$store.dispatch("report/reportCustomer", data);
          }
        },
      });
    },
  },
  filters: {
    toFixedWithSpace: (value) => {
      return parseFloat(value)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
    },
  },
};
</script>
